.stake-holders {
  width: 100%;
  overflow-x: hidden;
  .table_container {
    width: 99.9%;
    background-color: var(--secondary-background);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow-x: auto;

    table {
      width: 100%;

      thead th {
        color: var(--text-secondary);
        &:first-child {
          text-align: start;
        }
        &:last-child {
          text-align: end;
        }
      }

      tr {
        td,
        th {
          padding: 1.6rem 3.2rem;
          font-size: 1.6rem;
          font-weight: 500;
          border-bottom: 1px solid #212124;
          white-space: nowrap;
        }

        &:last-child {
          td {
            border-color: transparent;
          }
        }
      }

      tbody tr {
        cursor: pointer;
        td {
          text-align: center;
          &:first-child {
            display: flex;
            align-items: center;
            gap: 1rem;
          }
          &:last-child {
            text-align: end;
          }
        }
      }
    }
  }

  input[type='checkbox'] {
    accent-color: #62cb59;
  }
}
