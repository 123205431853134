@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --background: #141416;
  --secondary-background: #1a1a1d;
  --text-primary: #ffffff;
  --text-secondary: #888888;
  --card-background: #141416;
  --input-background: #101011;
  --btn-primary: #1d2cf3;
  --btn-primary-gradient: linear-gradient(
    92.97deg,
    #fd00fd 0.05%,
    #1d2cf3 100.05%
  );
  --header-height: 80px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  min-height: 100vh;

  @media (max-width: 768px) {
    font-size: 57.5%;
  }
  @media (max-width: 576px) {
    font-size: 55%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  color: var(--text-primary);
  overflow-x: hidden;
  width: 100%;
}

iframe {
  display: none !important;
}

a {
  text-decoration: none;
}

.app_container {
  display: grid;
  grid-template-columns: 300px auto;
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
}

.mx {
  max-width: 1400px;
  margin: 0 auto;
  background-color: red;
}

.pad {
  padding: 0 5rem;

  @media (max-width: 768px) {
    padding: 0 3rem;
  }
  @media (max-width: 576px) {
    padding: 0 2rem;
  }
}

.pointer {
  cursor: pointer;
}

@for $i from 5 to 51 {
  .p-#{$i} {
    padding: $i + px;
  }
  .pt-#{$i} {
    padding-top: $i + px;
  }
  .pl-#{$i} {
    padding-left: $i + px;
  }
  .pr-#{$i} {
    padding-right: $i + px;
  }
  .pb-#{$i} {
    padding-bottom: $i + px;
  }
  .m-#{$i} {
    margin: $i + px;
  }
  .mt-#{$i} {
    margin-top: $i + px;
  }
  .ml-#{$i} {
    margin-left: $i + px;
  }
  .mr-#{$i} {
    margin-right: $i + px;
  }
  .mb-#{$i} {
    margin-bottom: $i + px;
  }
}

p {
  .text-primary {
    color: var(--secondary);
  }
}

p,
hgroup {
  &.text-primary {
    color: var(--text-primary);
  }
  &.text-secondary {
    color: var(--text-secondary);
  }
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 4.4rem;
  color: var(--text-primary);
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.6rem;
  color: var(--text-primary);
}

p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  color: var(--text-secondary);
}

[data-position='flex-between'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

[data-position='flex'] {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.loader {
  display: grid;
  place-items: center;

  &.size-100 {
    svg {
      width: 100px;
      height: 100px;
    }
  }

  svg {
    width: 32px;
    height: 32px;
    animation: rotateanimation 2s linear infinite;

    @keyframes rotateanimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.no_referrals {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  svg {
    width: 50px;
    height: 50px;
  }
}
