@import '../../index.scss';

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #1e2328;

  h3 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .close {
    cursor: pointer;
  }
}

%base-styles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--card-background);
  border-radius: 20px;
  max-width: 480px;
  width: 100%;

  @media (max-width: 576px) {
    max-width: 90%;
  }

  .modal-header_title {
    font-size: 2.4rem;
    color: var(--text-primary);
  }
}

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  z-index: 100;
}

.transaction_modal {
  @extend %base-styles;
  padding: 2em;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2),
    inset 0px 0px 50px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(100px);

  &-content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      margin: 3.2rem 0 1.6rem;
    }

    .icon {
      width: 100px;
      height: 100px;
    }
  }
}

.wallet_modal {
  @extend %base-styles;
  padding: 1.5em;

  .flex-gap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }

  .wallet_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 1.5em;
    margin-top: 2em;

    &-card {
      box-sizing: border-box;
      cursor: pointer;
      background: #202123;
      border: 1px solid transparent;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 2rem 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transition: border 300ms linear;

      &:hover {
        border: 1px solid #1d2cf3;
      }

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
  }
}

.account_modal {
  @extend %base-styles;
  padding: 2em;

  .user_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    h2 {
      font-size: 2.5rem;
      font-weight: 500;
    }

    .flex {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

.nowallet_modal {
  @extend %base-styles;
  padding: 2em;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
}

.referrals_modal {
  @extend %base-styles;
  padding: 2em;

  .referrals_dropdown {
    &-header {
      background: #2f3033;
      border-radius: 5px;
      padding: 0.8rem 1.6rem;
      cursor: pointer;

      &.active {
        svg {
          transform: rotate(0deg);
        }
      }

      svg {
        transform: rotate(180deg);
      }
    }
    &-content {
      border: 1px solid #2f3033;
      border-radius: 0px 0px 5px 5px;
      > div {
        padding: 0.4rem 1.6rem;
        max-height: 300px;
        overflow-y: auto;
      }
    }
  }
}

.addplan_modal {
  @extend %base-styles;
  padding: 2em;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-height: 500px;
    overflow-y: auto;

    .form_input {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;

      label {
        font-size: 1.2rem;
      }

      input {
        width: 100%;
        outline: none;
        border: none;
        background: #161718;
        border-radius: 5px;
        color: #fff;
        font-size: 1.4rem;
        padding: 0.5rem 1rem;
      }

      .error_input {
        font-size: 1.2rem;
        color: tomato;
      }
    }

    .flex-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em;
    }
  }
}

.staked_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-header {
    > div {
      display: flex;
      border-radius: 100px;
      background: transparent;
      border: 1px solid #1b1c1e;

      button {
        padding: 8px 1em;
        background-color: transparent;
        border-radius: 100px;
        border: none;
        outline: none;
        color: #fff;
        cursor: pointer;
        transition: background-color 250ms linear;

        &.active {
          background-color: #1d2cf3;
        }
      }
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1em;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.referrals_list-wrapper {
  display: flex;
  flex-direction: column;

  &_two {
    margin-left: 2em;
    background-color: #1b1c1e;
    border-radius: 5px;

    > div:not(:last-child) {
      border-bottom: 1px solid #443d3d;
    }

    > div {
      padding: 5px 10px;
    }
  }
}
