.home {
  background-color: transparent;

  .btn {
    outline: none;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: var(--secondary-background);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: #fff;
    cursor: pointer;
  }

  .gcsplans_container {
    .flex-end {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;
    }

    .gcsplans_card_wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      .gcsplan-card {
        background-color: var(--secondary-background);
        border-radius: 5px;
        padding: 2rem;

        &-content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }
  }

  .base_container {
    margin-top: 5rem;
    .base_card {
      background-color: var(--secondary-background);
      border-radius: 5px;
      padding: 2rem;

      .flex-end {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
      }

      &-wrapper {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
      }
    }
  }
}
