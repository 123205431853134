.sidebar {
  height: 100%;
  background-color: var(--secondary-background);
  padding: 4rem 3rem;

  > nav {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #fff;
      transition: 200ms all;

      &.active {
        span {
          color: var(--btn-primary);
        }

        svg path {
          stroke: var(--btn-primary);
        }
      }
    }
  }
}
